<template>
    <header class="jumbotron">
        <div v-if="!buyingMode">
            <strong>Products:</strong>
            <table class="table table-hover" v-if="products">
            <thead>
            <tr>
                <th scope="col">Name</th>
                <th scope="col">Visible</th>
                <th scope="col">Price</th>
                <th scope="col">Created</th>
                <th scope="col">Buy</th>
            </tr>
            </thead>
            <tbody v-for="product in products.data" :key="product">
                <tr>
                    <td>
                        {{product.name}}
                    </td>
                    <td>
                        {{product.visible}}
                    </td>
                    <td>
                        {{product.price}} UAH
                    </td>
                    <td>
                        {{product.created_at}}
                    </td>
                    <td>
                        <button v-on:click.prevent="enableBuyingProductState(product)" class="btn btn-outline-success">Buy it!</button>
                    </td>
                </tr>
            </tbody>
        </table>
        </div>
        <div v-else style="text-align: center">
            <b class="text-color-red">Як оплатили нічо не торкайтеся і чекайте редіректа щоб чекнути час відповіді від EasyPay!!!</b>

            <p>QR code:</p>

            <div v-if="!buyingCurrentUrl" class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <vue-qrious
                v-else
                :value="buyingCurrentUrl"
                size="300"
            />

            <p>or link:</p>

            <div v-if="!buyingCurrentUrl" class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <a v-else :href="buyingCurrentUrl" target="_blank">{{buyingCurrentUrl}}</a>

            <p>Buying <b>{{buyingCurrentProduct.name}}</b> for <b class="text-color-red">{{buyingCurrentProduct.price}}</b><b> UAH</b></p>
            <button v-if="urlQueried" v-on:click.prevent="cancelBuyingMode()" class="btn btn-outline-danger">Cancel</button>


            <div v-if="messages.length > 0">
                <p class="bg-success">Придбано</p>
                <p>Received response:</p>
                <ul>
                    <li v-for="message in messages" v-bind:key="message">
                        {{message}}
                    </li>
                </ul>
            </div>
        </div>
    </header>
</template>

<script>
import ProductsService from "./services/products.service";
import {defineComponent} from "vue";
import PaymentService from "@/components/Device/services/payment.service";
import VueQrious from "vue-qrious";
import Pusher from "pusher-js";
Pusher.logToConsole = true;

export default defineComponent({
    name: "Products",
    props: {
        deviceId: null
    },

    components: {
        VueQrious,
    },

    data() {
        return {
            products: null,
            product: {
                id: null,
                unique_id: "",
                name: "",
                device_id: "",
                picture: "",
                products_content: {
                    volume: null,
                    device_tank: {
                        name: null,
                    }
                },
                visible: "",
                created_at: "",
                price: "",
            },
            buyingMode: null,
            buyingCurrentUrl: null,
            buyingCurrentProduct: null,
            urlQueried: false,
            messages: [],
        }
    },

    mounted() {
        this.getProductsBoard()
        this.setUpPusher();
    },

    methods: {
        getProductsBoard() {
            ProductsService.getProductsBoard().then(
                (response) => {
                    this.products = response.data;
                },
                (error) => {
                    this.products =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },

        enableBuyingProductState(product) {
            this.buyingMode = true;
            this.buyingCurrentProduct = product;

            PaymentService.getGeneratedLink(this.buyingCurrentProduct.unique_id).then(
                (response) => {
                    this.buyingCurrentUrl = response.data.data.paymentLink;
                    this.urlQueried = true;
                },
                (error) => {
                    this.products =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },

        cancelBuyingMode() {
            this.buyingMode = false
            this.buyingCurrentUrl = null;
            this.buyingCurrentProduct = null;
            this.urlQueried = false;
            this.messages = [];
        },

        setUpPusher() {
            let pusher = new Pusher('c7d8c71932aef858bcc6', {
                cluster: 'eu'
            });

            let channel = pusher.subscribe('payments-channel');
            channel.bind('payment-accepted', function (data) {
                this.messages.push(data);
            }, this);
        },
    },

    beforeUnmount() {
        this.cancelBuyingMode();
    }
})
</script>

<style scoped>

</style>
