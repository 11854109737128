import axios from 'axios';
import authHeader from "@/services/auth-header";

const API_URL = '/api/';

class ProductsService {
    getProductsBoard() {
        return axios.get(API_URL + 'own-products', {headers: authHeader()});
    }
}

export default new ProductsService();
