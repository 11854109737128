import axios from 'axios';
import authHeader from "@/services/auth-header";

const API_URL = '/api/';

class ProductsService {
    getGeneratedLink(productUniqueId) {
        let payload = {
            productsIds: {
                productUniqueId
            }
        }
        return axios.post(API_URL + 'payment-generate-link', payload, {headers: authHeader()});
    }
}

export default new ProductsService();
